'use client'
import React from 'react'
import { Wip } from '../components'

interface IProps {
  error: Error & { digest?: string }
  reset: () => void
}

const GlobalError = (props: IProps) => {
  const { error, reset } = props
  // eslint-disable-next-line no-console
  console.log('error->', error)
  return (
    <html lang="es">
      <body>
        <Wip debug reset={reset}/>
        <h2>Algo salió mal!</h2>
      </body>
    </html>
  )
}

export default GlobalError
