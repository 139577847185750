'use client'
import * as React from 'react'
import { useRouter } from 'next/navigation'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ParticleEffectButton from 'react-particle-effect-button';

import { setTimeoutPromise } from '@/lib/standard';

interface IButtonProps {
  text: string
  url: string
}

const ParticleButton = (props: IButtonProps) => {
  const { text, url } = props
  const [displayButton, setDisplayButton] = React.useState<boolean>(false)
  const router = useRouter();

  return (
    <ParticleEffectButton
      color='#292929'
      direction='bottom'
      duration={1200}
      easing='easeOutSine'
      speed={0.7}
      oscillationCoefficient={5}
      hidden={displayButton}
      onComplete={async () => await setTimeoutPromise(() => router.push(url), 100)} >
      <button
        className='bg-primary-950 text-white py-2 px-12 rounded-lg text-center cursor-pointer hover:bg-primary-500'
        role="button"
        onClick={() => setDisplayButton(!displayButton)}>
        {text}
      </button>
    </ParticleEffectButton>
  )
}

export default ParticleButton