import React from 'react'

import Link from 'next/link'
import { BeakerIcon } from '@heroicons/react/24/solid'

interface IProps {
  debug?: boolean
  reset?: () => void
}

const Wip = (props: IProps) => {
  const { debug, reset } = props
  return (
    <section className="mx-8 lg:mx-16 relative top-28 rounded-xl bg-white p-5 md:p-14 shadow mb-24 text-xl text-center">
      <div className="text-4xl font-semibold text-neutral-800 bg-primary-200 rounded w-full py-10 text-center">
        Página no disponible
      </div>
      <div className="py-4">
        Regresar al{' '}
        <Link
          href={'/'}
          className="py-1 px-2 rounded text-primary-400 hover:bg-primary-100 hover:text-secondary-400"
        >
          inicio
        </Link>
        {debug && reset && (
          <div className="px-4 text-right bottom-0">
            <button className="text-sm hover:text-primary-400" onClick={() => reset()}>
              <BeakerIcon
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              />
            </button>
          </div>
        )}
      </div>
    </section>
  )
}

export default Wip
