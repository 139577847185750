import * as React from 'react'
import { useEffect } from 'react'

import Link from 'next/link'

interface ISmoothScrollProps {
  name: React.ReactNode;
  href: string;
  selected: boolean;
  onClick: () => void;
}

const SmoothScroll: React.FC<ISmoothScrollProps> = (
  props: ISmoothScrollProps,
) => {
  const { name, href, selected, onClick } = props
  const linkRef = React.useRef<HTMLAnchorElement>(null)

  const handleClick = () => {
    onClick()
  }

  useEffect(() => {
    const linkElement = linkRef.current
    if (linkElement) {
      linkElement.addEventListener('click', (e) => {
        if (href.startsWith('#') && !href.startsWith('#http')) {
          e.preventDefault()
          const target = document.getElementById(href.substring(1))
          if (target) {
            window.scrollTo({
              top: target.offsetTop,
              behavior: 'smooth',
            })
          }
        }
      })
    }
  }, [href])

  //   const active = selected ? 'block' : 'hidden'
  const styled =
    selected == true
      ? 'py-4 md:py-1 px-1 uppercase border-b-4 border-secondary-300 font-semibold hover:py-0 hover:text-secondary-300 transition duration-300 hover:border-primary-400'
      : 'py-4 md:py-1 px-1 uppercase transition duration-300 font-semibold drop-shadow-lg t-shadow hover:py-0 hover:text-secondary-400 hover:border-t-2 hover:border-secondary-300'

  return (
    <>
      <Link className={styled} ref={linkRef} href={href} onClick={handleClick} scroll={false}>
        {name}
      </Link>
    </>
  )
}

export default SmoothScroll
